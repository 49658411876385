<template>
<div>
  <div id="appForm" class="mx-auto col-11 mt-5">
    <div class="flex flexLeft mx-auto contentsWidth">
      <p class="h4 text-center fw-3">{{base.year}}年度 {{base.name}} - 第{{sort}}回計画変更申請</p>
    </div>
    <hr class="title contentsWidth">

    <div class="notice mx-auto contentsWidth my-4 py-3 px-4">
      <p class="mb-1">・変更したい項目の「変更する」ボタンを押し、変更内容を入力してください。</p>
      <p class="mb-1">・数字は半角で入力してください。</p>
      <p class="mb-1">・添付ファイルについては、申請後に閲覧できます。</p>
      <p
        v-if="assets.length > 0"
        class="mb-1">・添付ファイルの原本は以下からダウンロードしてください。</p>
      <p
        v-for="(asset, index) in assets" :key="`openFile_${index}`"
        class="mb-0 ml-5"><b-link
              @click="downloadOpenFile(asset.id, asset.name)">
                {{asset.name}}
              </b-link></p>
    </div>
    <div class="mb-3 mx-auto contentsWidth">

    <div>
      <v-app-controlview
        :code="base.code"
        :status="change.status"
        :comments="comments"/>
    </div>

    <div class="mt-5">
      <div
        v-for="(field, index) in fields" :key="index"
        class="col-12 px-0">
        <v-app-field-change
          :fieldId='field.field_id'
          :fieldLabel="field.label"
          :fieldType="field.type"
          :description="field.description"
          :notice="field.notice"
          :is_required="field.is_required"
          :type="field.type"
          :label="field.label"
          :is_variable="field.is_variable"
          :input_number="field.input_number"
          :min_input_number="field.min_input_number"
          :max_input_number="field.max_input_number"
          :view_template="field.view_template"
          :options.sync="field.options"
          :rules="field.rules"
          :messages="field.messages"
          :params="field.params"
          :writable="writable"
          :isChange="isChangeData[field.field_id]"
          :canChangePlan="field.can_change_plan"
          :approvalRequired="field.approval_required"
          :downloadFormFile="downloadFormFile"
          :isChangeReason="field.is_change_reason"
          :downloadApploadedFile="downloadApploadedFile"
          @switchChange="switchChange"
        />
          <!-- :writable="writable"
          :assets="assets"
          :downloadFormFile="downloadFormFile"
          :downloadOpenFile="downloadOpenFile" -->
        <hr>
      </div>
    </div>

    <div class="contentsWidth mt-4 mb-3 flex flexCenter flexColumn">
      <p class="mb-1">添付ファイルについては、申請後に閲覧できます。</p>
    </div>

    <div class="mt-4 mb-3 flex flexCenter contentsWidth">
      <a href="/university/applications/change/list" class="btn btn btn-lg mr-4 bold btn-secondary" target="_self">一覧に戻る</a>
      <b-button
        v-if="writable"
        :disabled="!hasChange"
        @click="save"
        type="button"
        variant="primary"
        class="btn bold mr-4">
        申請</b-button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import api from '@/modules/api';
// eslint-disable-next-line
import download from '@/modules/download';
import AppControlview from '@/components/gas/parts/ChangeControlview.vue';
import FieldChange from '@/components/parts/application/FieldChange.vue';
import CONST_STATUS from '@/constants/appStatus';
import USECASE from '@/constants/usecase';

export default {
  name: 'gasChangeForm',
  components: {
    'v-app-field-change': FieldChange,
    'v-app-controlview': AppControlview,
  },
  data() {
    return {
      sort: null,
      // 申請データ
      base: {},
      change: {},
      // 入力欄データ
      fields: {},
      usecases: {},
      constUsecases: USECASE.USECASE,
      isReturn: true,
      isChangeData: {},
      assets: {},
      comments: {},
    };
  },
  computed: {
    hasChange() {
      let result = false;
      Object.keys(this.isChangeData).forEach((fieldId) => {
        if (this.isChangeData[fieldId] === true) {
          result = true;
          // eslint-disable-next-line
          return;
        }
      });
      return result;
    },
    // 受領の場合は編集不可
    writable() {
      return this.change.status !== CONST_STATUS.APP_STATUS.RECEIPT;
    },
    upFileKeys() {
      return this.$store.state.gasApplicationInput.upFileKeys;
    },
    tempUpFileList() {
      return this.$store.state.gasApplicationInput.tempUpFileList;
    },
  },
  methods: {
    async downloadOpenFile(assetId, assetName) {
      const param = {
        assetId,
        assetName,
      };
      this.$store.dispatch('page/onWaiting');
      const requireBlob = true;
      const response = await api.send('/api/uni/change/download/file', param, requireBlob)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, assetName);
    },
    async fetchData(appId, sort) {
      this.$store.dispatch('page/onLoading');
      this.change = {};
      const param = { appId, sort };
      const response = await api.send('/api/uni/change/edit', param)
        .catch((err) => {
          console.error(err);
        });
      const { data } = response;
      this.base = data.base;
      this.fields = data.fields;
      this.usecases = data.usecases;
      this.assets = data.assets;
      if (data.change) {
        this.change = data.change;
        this.comments = data.change.comments;
      }
      const dbDataOld = { fields: data.fields, answers: data.old_answers };
      const dbDataNew = { fields: data.fields, answers: data.answers, reasons: data.reasons };
      this.setIsChangeList(dbDataNew);
      this.$store.commit('gasApplicationOldInput/setDbDataNoPage', dbDataOld);
      this.$store.commit('gasApplicationInput/setDbDataNoPage', dbDataNew);
      this.$store.dispatch('page/offLoading');
    },
    async save() {
      if (!await this.confirm('現在の内容で提出をしてもよろしいですか？')) {
        return;
      }
      this.pageErrs = [];
      this.$store.dispatch('page/onWaiting');
      const storeData = {
        sort: this.sort,
        appId: this.base.id,
        apptypeId: this.base.application_type_id,
        inputs: JSON.stringify(this.$store.state.gasApplicationInput.inputs),
        isChangeData: JSON.stringify(this.isChangeData),
        changeReasonInputs: JSON.stringify(this.$store.state.gasApplicationInput.changeReasonInputs),
      };
      const { files } = this.$store.state.gasApplicationInput;
      Object.keys(files).forEach((key) => {
        storeData[key] = files[key];
      });
      const response = await api.sendForm('/api/uni/change/save', storeData)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert('入力内容に誤りがありました。修正してください。', false);
            this.$store.commit('gasApplicationInput/setErrMessages', err.response.data.formErrs);
            this.$store.commit('userInfo/setErrorMsg', err.response.data.userErrs);
            this.pageErrs = err.response.data.pageErrs;
          }
          return false;
        });
      if (!response) {
        this.$store.dispatch('page/offWaiting');
        return;
      }
      let msg = '';
      if (response.data.isApproval) {
        msg = '計画変更申請を完了しました。変更内容は申請書に反映されました。';
      } else {
        msg = '計画変更申請を完了しました。財団事務局による承認をお待ち下さい。';
      }
      await this.alert(msg, false);
      this.$store.dispatch('page/offWaiting');
      const { appId, sort } = this.$route.params;
      this.sort = sort;
      await this.fetchData(appId, sort);
    },
    async downloadFormFile(fieldId, optionId, fileName, isOld) {
      const param = {
        application_id: this.base.id,
        sort: this.sort,
        field_id: fieldId,
        option_id: optionId,
        answer_no: 1, // ファイルは当面１つ固定
        is_old: isOld,
      };
      const url = '/api/uni/change/download/uploadedFile';
      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
    setIsChangeList(dbDataNew) {
      const isChangeData = {};
      const canChangePlanList = {};

      this.fields.forEach((field) => {
        isChangeData[field.field_id] = false;
        canChangePlanList[field.field_id] = field.can_change_plan;
      });
      // 回答がある場合は、その変更フラグをONにする
      if (dbDataNew.answers) {
        dbDataNew.answers.forEach((ansewer) => {
          if (canChangePlanList[ansewer.field_id]) {
            isChangeData[ansewer.field_id] = true;
          }
        });
      }
      this.isChangeData = isChangeData;
    },
    switchChange(fieldId) {
      this.$set(this.isChangeData, fieldId, !this.isChangeData[fieldId]);
    },
    async downloadApploadedFile(uploadedFileKey, index, fileName) {
      const fileKey = this.upFileKeys[uploadedFileKey][index];
      const url = '/api/uni/confupfile/fileDownload';
      this.$store.dispatch('page/onWaiting');
      if (!this.tempUpFileList[uploadedFileKey] || !this.tempUpFileList[uploadedFileKey][fileKey]) {
        const itemNumber = `${uploadedFileKey}-${fileKey}`;
        const tempUpUrl = '/api/uni/confupfile';
        const tempParam = {
          itemNumber: JSON.stringify(itemNumber),
        };
        const { files } = this.$store.state.gasApplicationInput;
        tempParam.file = files[uploadedFileKey];
        const tempResponse = await api.sendForm(tempUpUrl, tempParam, true)
          .catch((err) => {
            console.error(err);
          });
        if (!tempResponse) {
          this.$store.dispatch('page/offWaiting');
          await this.alert('ファイルのダウンロードに失敗しました。再度アップロードしてお試しください。');
          return;
        }
        const saveTempResponse = {
          target: uploadedFileKey,
          subKey: fileKey,
          path: tempResponse.data.path,
        };
        this.$store.commit('gasApplicationInput/saveTempPath', saveTempResponse);
      }
      const path = this.tempUpFileList[uploadedFileKey][fileKey];
      const param = {
        fileName: path,
      };
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
  },
  // ロード画面
  async created() {
    const { appId, sort } = this.$route.params;
    this.sort = sort;
    await this.fetchData(appId, sort);
  },
};
</script>

<style scoped>
  .notice {
    background-color: #F3F3F3;
  }

  .bottonArea {
    width: 1100px;
  }

  .label-wrap {
    width: 270px;
    margin-right: 30px;
  }
  .input-wrap {
    width: 780px;
  }

  input.form-control {
    height: 50px;
  }

  .change-btn {
    margin-top: 10px;
    width: 100px;
    height: 40px !important;
    font-size: 14px !important;
    font-weight: bold;
    background-color: #fff !important;
    border-radius: 18px;
  }

  .on-change {
    border: 2px solid #293CB0 !important;
    color: #293CB0 !important;
  }

  .off-change {
    border: 2px solid #8E8E8E !important;
    color: #8E8E8E !important;
  }

  hr.change {
    border-top: 3px double;
  }

  div.short-middle-wrap {
    width: 335px;
  }

  div.short-middle-wrap-second {
    margin-left: 10px;
  }

  div.input-left-wrap {
    width: 680px;
  }
  div.input-right-wrap {
    margin-left: 10px;
    width: 70px;
  }

  div.close-wrap {
    top: 50px;
    position: relative;
  }

  .drop {
    height: 120px;
    width: 100%;
    background-color: #F3F3F3;
    border-color: #CCC;
    border-style: dashed;
    border-radius: 10px;
    border-width: 1px;
  }

  .dragText {
    color: #BBB;
  }

  .fileText {
    color: #0A8EA7;
  }

  .fileNotation {
    color: #BBB;
  }

  .is-dragging.drop {
    background-color: #E6E6E6;
    border-color: #6E6E6E;
  }

  .is-dragging {
    color: #6E6E6E;
  }

  .selectFile {
    cursor: pointer;
  }
  .selectFile:hover {
    text-decoration: underline;
  }

  .fileName {
    margin-top: 16px;
  }

  .control-wrap {
    background-color: #DDD;
  }

  .cotrol-line {
    height: 2px;
    background-color: #BBB;
  }

  .opacity03 {
    opacity: 0.3;
  }

</style>
