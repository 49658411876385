<template>
  <perfect-scrollbar class="p-4 ContentsBox">

    <b-row class="mt-2 pb-2 line">
      <b-col sm="3" class="pl-3 pr-1 mb-0">
        <div class="labelWrap mb-0">
          <label class="formLabel mb-0 bold">申請番号</label>
        </div>
      </b-col>
      <b-col sm="9">
        {{code}}
      </b-col>
    </b-row>
    <hr>

    <b-row class="mt-2 pb-2 line">
      <b-col sm="3" class="pl-3 pr-1 mb-0">
        <div class="labelWrap mb-0">
          <label class="formLabel mb-0 bold">ステータス</label>
        </div>
      </b-col>
      <b-col sm="9">
        <span v-if="status">{{statusTexts[status]}}</span>
        <span v-if="!status">未提出</span>
      </b-col>
    </b-row>
    <hr v-if="viewReason || comments.length > 0">

    <b-row class="mt-2 pb-2"
      v-if="viewReason || comments.length > 0">
      <b-col sm="3" class="pl-3 pr-1 mb-0">
        <div class="labelWrap mb-0">
          <label class="formLabel mb-0 bold">返却・承認理由</label>
        </div>
      </b-col>
      <b-col sm="9">
        <div>
          <div v-for="(comment, comment_no) in comments" :key="`comment_${comment_no}`">
            <span v-if="comment_no === 0">最新：登録日 {{comment.reg_date}}</span>
            <span v-else>{{comments.length - comment_no}}回目：登録日 {{comment.reg_date}}</span>
            <b-form-textarea
              disabled
              :value="comment.comment"
              :rows="4"
              />
            <hr v-if="comment_no !== (comments.length-1)">
          </div>
        </div>
      </b-col>
    </b-row>

    <div>
    </div>

  </perfect-scrollbar>
</template>

<script>
import CONST_STATUS from '@/constants/appStatus';

export default {
  name: 'gasApplicationChangeControlview',
  components: {
  },
  props: {
    code: { type: String, default: null },
    status: { type: Number, default: null },
    reason: { type: String, default: '' },
    comments: { type: Object, default: () => [] },
  },
  data() {
    return {
      statusTexts: CONST_STATUS.APP_CHANGE_STATUS_TEXTS,
    };
  },
  methods: {
  },
  computed: {
    viewReason() {
      const hasReasonStatusList = CONST_STATUS.HAS_REASON_STATUS;
      return hasReasonStatusList.indexOf(this.status) !== -1;
    },
  },
  // ロード画面
  created() {
  },
};
</script>

<style scoped>

  .controlBox {
  border: solid 1px;
  height: 610px;
  width: 1080px;
  position: relative;
  border-color: #CCC !important;
  background-color: #FFF;
  }

  .labelWrap {
    display: inline-block;
  }

  .line {
    min-height: 50px;
  }
</style>
